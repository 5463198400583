import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgxsModule} from '@ngxs/store';
import {TuiTextfieldControllerModule} from '@taiga-ui/core';
import {TuiInputModule} from '@taiga-ui/kit';
import {Ng2FlatpickrModule} from 'ng2-flatpickr';

import {FooterComponent} from './components/footer/footer.component';
import {SearchToolbarComponent} from './components/search-toolbar/search-toolbar.component';
import {SgpSidebarModule} from './components/sidebar/sidebar.module';
import {DbClickPreventDirective} from './directives/db-click-prevent.directive';
import {HoverDirective} from './directives/hover.directive';
import {OutsideClickDirective} from './directives/outside-click.directive';
import {SidebarDirective} from './directives/sidebar.directive';
import {ObjectFieldsEmptyPipe} from './pipes/object-fields-empty.pipe';
import {PluralPipe} from './pipes/plural.pipe';
import {SharedServiceModule} from './services/shared-service.module';
import {SharedState} from './store/shared.state';
import {YandexMetrikaGoalDirective} from '@app/core/directives/yandex-metrika-goal.directive';

const PIPES = [PluralPipe, ObjectFieldsEmptyPipe];

const COMPONENTS = [
  FooterComponent,
  HoverDirective,
  OutsideClickDirective,
  SidebarDirective,
  DbClickPreventDirective,
  YandexMetrikaGoalDirective,
];

@NgModule({
  imports: [
    SearchToolbarComponent,
    NgxsModule.forFeature([SharedState]),
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    Ng2FlatpickrModule,
    SharedServiceModule,
    TuiTextfieldControllerModule,
    TuiInputModule,
    SgpSidebarModule,
  ],
  exports: [
    SearchToolbarComponent,
    CommonModule,
    FormsModule,
    Ng2FlatpickrModule,
    ReactiveFormsModule,
    SgpSidebarModule,
    SharedServiceModule,
    ...COMPONENTS,
    ...PIPES,
  ],
  declarations: [...COMPONENTS, ...PIPES],
})
export class SharedModule {}
