import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, ReactiveFormsModule} from '@angular/forms';
import {DynamicFilter} from '@app/shared/model/filter';
import {autocompleteFilter} from '@app/shared/rxjs-utils/autocomplete-filter.operator';
import {TuiDestroyService} from '@taiga-ui/cdk';
import {TuiTextfieldControllerModule} from '@taiga-ui/core';
import {TuiInputModule} from '@taiga-ui/kit';
import {takeUntil, tap} from 'rxjs/operators';

@Component({
  standalone: true,
  selector: 'sgp-search-toolbar',
  templateUrl: './search-toolbar.component.html',
  styleUrls: ['./search-toolbar.component.scss'],
  providers: [TuiDestroyService],
  imports: [TuiInputModule, TuiTextfieldControllerModule, ReactiveFormsModule],
})
export class SearchToolbarComponent implements OnInit {
  @Input() value: string;
  @Input() placeholder: string;
  @Input() dynamicFilterDebounceTime = 400;
  @Input() searchKey = 'dynamicFilter';

  @Output() dynamicFilter = new EventEmitter<DynamicFilter>();

  readonly dynamicFormInput: FormControl = new FormControl(null);

  constructor(private readonly destroy$: TuiDestroyService) {}

  ngOnInit() {
    if (this.value) {
      this.dynamicFormInput.setValue(this.value);
    }

    this.initListener();
  }

  private initListener() {
    this.dynamicFormInput.valueChanges
      .pipe(
        autocompleteFilter(this.dynamicFilterDebounceTime),
        tap(value => {
          const data = {
            [this.searchKey]: value,
          };

          this.dynamicFilter.emit(data);
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }
}
